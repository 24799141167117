*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

.tooltip-arrow, .tooltip-arrow:before {
  background: inherit;
  width: 8px;
  height: 8px;
  position: absolute;
}

.tooltip-arrow {
  visibility: hidden;
}

.tooltip-arrow:before {
  content: "";
  visibility: visible;
  transform: rotate(45deg);
}

[data-tooltip-style^="light"] + .tooltip > .tooltip-arrow:before {
  border-style: solid;
  border-color: var(--color-gray-200);
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="top"] > .tooltip-arrow:before {
  border-bottom-width: 1px;
  border-right-width: 1px;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="right"] > .tooltip-arrow:before {
  border-bottom-width: 1px;
  border-left-width: 1px;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="bottom"] > .tooltip-arrow:before {
  border-top-width: 1px;
  border-left-width: 1px;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="left"] > .tooltip-arrow:before {
  border-top-width: 1px;
  border-right-width: 1px;
}

.tooltip[data-popper-placement^="top"] > .tooltip-arrow {
  bottom: -4px;
}

.tooltip[data-popper-placement^="bottom"] > .tooltip-arrow {
  top: -4px;
}

.tooltip[data-popper-placement^="left"] > .tooltip-arrow {
  right: -4px;
}

.tooltip[data-popper-placement^="right"] > .tooltip-arrow {
  left: -4px;
}

.tooltip.invisible > .tooltip-arrow:before {
  visibility: hidden;
}

[data-popper-arrow], [data-popper-arrow]:before {
  background: inherit;
  width: 8px;
  height: 8px;
  position: absolute;
}

[data-popper-arrow] {
  visibility: hidden;
}

[data-popper-arrow]:before {
  content: "";
  visibility: visible;
  transform: rotate(45deg);
}

[data-popper-arrow]:after {
  content: "";
  visibility: visible;
  background: inherit;
  width: 9px;
  height: 9px;
  position: absolute;
  transform: rotate(45deg);
}

[role="tooltip"] > [data-popper-arrow]:before {
  border-style: solid;
  border-color: var(--color-gray-200);
}

.dark [role="tooltip"] > [data-popper-arrow]:before {
  border-style: solid;
  border-color: var(--color-gray-600);
}

[role="tooltip"] > [data-popper-arrow]:after {
  border-style: solid;
  border-color: var(--color-gray-200);
}

.dark [role="tooltip"] > [data-popper-arrow]:after {
  border-style: solid;
  border-color: var(--color-gray-600);
}

[data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow]:after {
  border-bottom-width: 1px;
  border-right-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow]:after {
  border-bottom-width: 1px;
  border-left-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow]:after {
  border-top-width: 1px;
  border-left-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow]:after {
  border-top-width: 1px;
  border-right-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow] {
  bottom: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow] {
  top: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow] {
  right: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow] {
  left: -5px;
}

[role="tooltip"].invisible > [data-popper-arrow]:before, [role="tooltip"].invisible > [data-popper-arrow]:after {
  visibility: hidden;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  border-color: var(--color-gray-500);
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: var(--color-blue-600);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: var(--color-blue-600);
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: var(--color-gray-500);
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

select:not([size]) {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 10 6'%3e %3cpath stroke='%23E4E7EF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m1 1 4 4 4-4'/%3e %3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: .75em .75em;
  padding-right: 2.5rem;
}

[dir="rtl"] select:not([size]) {
  background-position: .75rem;
  padding-left: 0;
  padding-right: .75rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: var(--color-blue-600);
  border-color: --color-gray-500;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: var(--color-blue-600);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked, .dark [type="checkbox"]:checked, .dark [type="radio"]:checked {
  background-position: center;
  background-repeat: no-repeat;
  background-size: .55em .55em;
  background-color: currentColor !important;
  border-color: #0000 !important;
}

[type="checkbox"]:checked {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'%3e %3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M1 5.917 5.724 10.5 15 1.5'/%3e %3c/svg%3e");
  background-repeat: no-repeat;
  background-size: .55em .55em;
}

[type="radio"]:checked, .dark [type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  background-size: 1em 1em;
}

[type="checkbox"]:indeterminate {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'%3e %3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M0.5 6h14'/%3e %3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: .55em .55em;
  background-color: currentColor !important;
  border-color: #0000 !important;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor !important;
  border-color: #0000 !important;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px auto inherit;
}

input[type="file"]::file-selector-button {
  color: #fff;
  background: var(--color-gray-800);
  cursor: pointer;
  border: 0;
  margin-inline: -1rem 1rem;
  padding: .625rem 1rem .625rem 2rem;
  font-size: 0;
  font-weight: 500;
}

input[type="file"]::file-selector-button:hover {
  background: var(--color-gray-700);
}

[dir="rtl"] input[type="file"]::file-selector-button {
  padding-left: 1rem;
  padding-right: 2rem;
}

.dark input[type="file"]::file-selector-button {
  color: #fff;
  background: var(--color-gray-600);
}

.dark input[type="file"]::file-selector-button:hover {
  background: var(--color-gray-500);
}

input[type="range"]::-webkit-slider-thumb {
  background: var(--color-blue-600);
  appearance: none;
  cursor: pointer;
  border: 0;
  border-radius: 9999px;
  width: 1.25rem;
  height: 1.25rem;
}

input[type="range"]:disabled::-webkit-slider-thumb {
  background: var(--color-gray-400);
}

.dark input[type="range"]:disabled::-webkit-slider-thumb {
  background: var(--color-gray-500);
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1px;
  --tw-ring-color: rgb(164 202 254 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

input[type="range"]::-moz-range-thumb {
  background: var(--color-blue-600);
  appearance: none;
  cursor: pointer;
  border: 0;
  border-radius: 9999px;
  width: 1.25rem;
  height: 1.25rem;
}

input[type="range"]:disabled::-moz-range-thumb {
  background: var(--color-gray-400);
}

.dark input[type="range"]:disabled::-moz-range-thumb {
  background: var(--color-gray-500);
}

input[type="range"]::-moz-range-progress {
  background: var(--color-blue-500);
}

input[type="range"]::-ms-fill-lower {
  background: var(--color-blue-500);
}

.toggle-bg:after {
  content: "";
  border-color: var(--color-gray-300);
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  background: #fff;
  border-width: 1px;
  border-radius: 9999px;
  width: 1.25rem;
  height: 1.25rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  position: absolute;
  top: .125rem;
  left: .125rem;
}

input:checked + .toggle-bg:after {
  border-color: #fff;
  transform: translateX(100%);
}

input:checked + .toggle-bg {
  background: var(--color-blue-600);
  border-color: var(--color-blue-600);
}

a {
  color: #273456;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

a:hover, a.is-primary {
  --tw-text-opacity: 1;
  color: rgb(0 208 166 / var(--tw-text-opacity));
}

a.is-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(0 172 170 / var(--tw-text-opacity));
}

html {
  color: #273456;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

form label.required:before {
  color: #00d0a6;
  content: "* ";
}

form > .field:not(:last-child) {
  margin-bottom: 1.875rem;
}

form input::placeholder, select:invalid {
  color: #bec2cc;
}

a.anchor {
  visibility: hidden;
  display: block;
  position: relative;
  top: -150px;
}

button {
  font-size: 1rem;
}

button[data-carousel-slide-to] {
  background-color: #0000;
  border-width: 1px;
  border-color: #0000;
}

button[data-carousel-slide-to][aria-current="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(102 77 159 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

button[data-carousel-slide-to]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(102 77 159 / var(--tw-border-opacity));
}

button[data-carousel-slide-to]:hover:not([aria-current="true"]) {
  background-color: #0000;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

.selectedCell {
  background-color: var(--color-gray-50);
}

.dark .selectedCell {
  background-color: var(--color-gray-700);
}

.datatable-wrapper {
  width: 100%;
}

.datatable-wrapper .datatable-top {
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: start;
  gap: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

@media (width >= 640px) {
  .datatable-wrapper .datatable-top {
    flex-direction: row-reverse;
    align-items: center;
  }
}

.datatable-wrapper .datatable-search .datatable-input, .datatable-wrapper .datatable-input {
  color: var(--color-gray-900);
  border: 1px solid var(--color-gray-300);
  background-color: var(--color-gray-50);
  border-radius: .5rem;
  min-width: 16rem;
  font-size: 0;
}

.dark .datatable-wrapper .datatable-search .datatable-input, .dark .datatable-wrapper .datatable-input {
  color: #fff;
  background-color: var(--color-gray-800);
  border: 1px solid var(--color-gray-700);
}

.datatable-wrapper thead th .datatable-input {
  color: var(--color-gray-900);
  background-color: #fff;
  min-width: 0;
  padding-top: .35rem;
  padding-bottom: .35rem;
  font-weight: 400;
}

.dark .datatable-wrapper thead th .datatable-input {
  background-color: var(--color-gray-700);
  border-color: var(--color-gray-600);
  color: #fff;
}

.datatable-wrapper .datatable-top .datatable-dropdown {
  color: var(--color-gray-500);
  font-size: 0;
}

.dark .datatable-wrapper .datatable-top .datatable-dropdown {
  color: var(--color-gray-400);
}

.datatable-wrapper .datatable-top .datatable-dropdown .datatable-selector {
  background-color: var(--color-gray-50);
  color: var(--color-gray-900);
  border: 1px solid var(--color-gray-300);
  border-radius: .5rem;
  min-width: 4rem;
  margin-right: .25rem;
  font-size: 0;
}

.dark .datatable-wrapper .datatable-top .datatable-dropdown .datatable-selector {
  background-color: var(--color-gray-800);
  border: 1px solid var(--color-gray-700);
  color: #fff;
}

.datatable-wrapper .datatable-container thead tr.search-filtering-row th {
  padding-top: 0;
}

.datatable-wrapper .datatable-search .datatable-input:focus {
  border-color: var(--color-blue-600);
}

.datatable-wrapper .datatable-container {
  overflow-x: auto;
}

.datatable-wrapper .datatable-table {
  color: var(--color-gray-500);
  text-align: left;
  width: 100%;
  font-size: 0;
}

.dark .datatable-wrapper .datatable-table {
  color: var(--color-gray-400);
}

.datatable-wrapper .datatable-table thead {
  color: var(--color-gray-500);
  background-color: var(--color-gray-50);
  font-size: 0;
}

.dark .datatable-wrapper .datatable-table thead {
  color: var(--color-gray-400);
  background-color: var(--color-gray-800);
}

.datatable-wrapper .datatable-table thead th {
  white-space: nowrap;
}

.datatable-wrapper .datatable-table thead th, .datatable-wrapper .datatable-table tbody th, .datatable-wrapper .datatable-table tbody td {
  padding: .75rem 1.5rem;
  width: auto !important;
}

.datatable-wrapper .datatable-table thead th .datatable-sorter, .datatable-wrapper .datatable-table thead th {
  text-transform: uppercase;
}

.datatable-wrapper .datatable-table thead th .datatable-sorter:hover, .datatable-wrapper .datatable-table thead th.datatable-ascending .datatable-sorter, .datatable-wrapper .datatable-table thead th.datatable-descending .datatable-sorter {
  color: var(--color-gray-900);
}

.dark .datatable-wrapper .datatable-table thead th .datatable-sorter:hover, .dark .datatable-wrapper .datatable-table thead th.datatable-ascending .datatable-sorter, .dark .datatable-wrapper .datatable-table thead th.datatable-descending .datatable-sorter {
  color: #fff;
}

.datatable-wrapper .datatable-table tbody tr.selected {
  background-color: var(--color-gray-100);
}

.dark .datatable-wrapper .datatable-table tbody tr.selected {
  background-color: var(--color-gray-700);
}

.datatable-wrapper .datatable-table tbody tr {
  border-bottom: 1px solid var(--color-gray-200);
}

.dark .datatable-wrapper .datatable-table tbody tr {
  border-bottom: 1px solid var(--color-gray-700);
}

.datatable-wrapper .datatable-table .datatable-empty {
  text-align: center;
}

.datatable-wrapper .datatable-bottom {
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 1rem;
  margin-top: 1rem;
  display: flex;
}

@media (width >= 640px) {
  .datatable-wrapper .datatable-bottom {
    flex-direction: row;
    align-items: center;
  }
}

.datatable-wrapper .datatable-bottom .datatable-info {
  color: var(--color-gray-500);
  font-size: 0;
}

.dark .datatable-wrapper .datatable-bottom .datatable-info {
  color: var(--color-gray-400);
}

.datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list {
  align-items: center;
  height: 2rem;
  font-size: 0;
  display: flex;
}

.datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item-link {
  color: var(--color-gray-500);
  border-top: 1px solid var(--color-gray-300);
  border-bottom: 1px solid var(--color-gray-300);
  border-right: 1px solid var(--color-gray-300);
  align-items: center;
  height: 2rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: 0;
  font-weight: 500;
  display: flex;
}

.dark .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item-link {
  color: var(--color-gray-400);
  border-color: var(--color-gray-700);
}

.datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:first-of-type, .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:last-of-type {
  position: relative;
}

.datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:first-of-type .datatable-pagination-list-item-link, .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:last-of-type .datatable-pagination-list-item-link, .dark .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:first-of-type .datatable-pagination-list-item-link, .dark .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:last-of-type .datatable-pagination-list-item-link {
  color: #0000;
}

.datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:first-of-type .datatable-pagination-list-item-link:after {
  content: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 24 24'%3e %3cpath stroke='%23E4E7EF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m14 8-4 4 4 4'/%3e %3c/svg%3e");
  width: 1.3rem;
  height: 1.3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:first-of-type .datatable-pagination-list-item-link:hover:after {
  content: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 24 24'%3e %3cpath stroke='%23D9DCE5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m14 8-4 4 4 4'/%3e %3c/svg%3e");
}

.dark .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:first-of-type .datatable-pagination-list-item-link:after {
  content: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 24 24'%3e %3cpath stroke='%239ca3af' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m14 8-4 4 4 4'/%3e %3c/svg%3e");
}

.dark .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:first-of-type .datatable-pagination-list-item-link:hover:after {
  content: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 24 24'%3e %3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m14 8-4 4 4 4'/%3e %3c/svg%3e");
}

.datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:last-of-type .datatable-pagination-list-item-link:after {
  content: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 24 24'%3e %3cpath stroke='%23E4E7EF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m10 16 4-4-4-4'/%3e %3c/svg%3e");
  width: 1.3rem;
  height: 1.3rem;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:last-of-type .datatable-pagination-list-item-link:hover:after {
  content: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 24 24'%3e %3cpath stroke='%23D9DCE5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m10 16 4-4-4-4'/%3e %3c/svg%3e");
}

.dark .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:last-of-type .datatable-pagination-list-item-link:after {
  content: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 24 24'%3e %3cpath stroke='%239ca3af' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m10 16 4-4-4-4'/%3e %3c/svg%3e");
}

.dark .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:last-of-type .datatable-pagination-list-item-link:hover:after {
  content: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 24 24'%3e %3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m10 16 4-4-4-4'/%3e %3c/svg%3e");
}

.datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:first-of-type .datatable-pagination-list-item-link {
  border-left: 1px solid var(--color-gray-300);
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.dark .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:first-of-type .datatable-pagination-list-item-link {
  border-left: 1px solid var(--color-gray-700);
}

.datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:last-of-type .datatable-pagination-list-item-link {
  border-left: 0;
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item-link:hover {
  background-color: var(--color-gray-50);
  color: var(--color-gray-700);
}

.dark .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item-link:hover {
  background-color: var(--color-gray-700);
  color: #fff;
}

.apexcharts-canvas .apexcharts-tooltip {
  color: var(--color-gray-700) !important;
  background-color: #fff !important;
  border: 0 !important;
  border-radius: .25rem !important;
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a !important;
}

.dark .apexcharts-canvas .apexcharts-tooltip {
  background-color: var(--color-gray-700) !important;
  color: var(--color-gray-400) !important;
  border-color: #0000 !important;
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a !important;
}

.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-title {
  background-color: var(--color-gray-100) !important;
  border-bottom-color: var(--color-gray-200) !important;
  color: var(--color-gray-500) !important;
  margin-bottom: .75rem !important;
  padding: .5rem .75rem !important;
  font-size: 0 !important;
  font-weight: 400 !important;
}

.dark .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-title {
  background-color: var(--color-gray-600) !important;
  border-color: var(--color-gray-500) !important;
  color: var(--color-gray-500) !important;
}

.apexcharts-canvas .apexcharts-xaxistooltip {
  color: var(--color-gray-500) !important;
  background-color: #fff !important;
  border-color: #0000 !important;
  border-radius: .25rem !important;
  padding: .5rem .75rem !important;
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a !important;
}

.dark .apexcharts-canvas .apexcharts-xaxistooltip {
  color: var(--color-gray-400) !important;
  background-color: var(--color-gray-700) !important;
}

.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-text-y-label {
  color: var(--color-gray-500) !important;
  font-size: 0 !important;
}

.dark .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-text-y-label {
  color: var(--color-gray-400) !important;
}

.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-text-y-value {
  color: var(--color-gray-900);
  font-size: 0 !important;
}

[dir="rtl"] .apexcharts-tooltip .apexcharts-tooltip-marker {
  margin-right: 0 !important;
  margin-left: e !important;
}

.dark .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-text-y-value {
  color: #fff !important;
}

.apexcharts-canvas .apexcharts-xaxistooltip-text {
  font-size: 0 !important;
  font-weight: 400 !important;
}

.apexcharts-canvas .apexcharts-xaxistooltip:after, .apexcharts-canvas .apexcharts-xaxistooltip:before {
  border-bottom-color: #fff !important;
}

.apexcharts-canvas .apexcharts-xaxistooltip:after {
  border-width: 8px !important;
  margin-left: -8px !important;
}

.apexcharts-canvas .apexcharts-xaxistooltip:before {
  border-width: 10px !important;
  margin-left: -10px !important;
}

.dark .apexcharts-canvas .apexcharts-xaxistooltip:after, .dark .apexcharts-canvas .apexcharts-xaxistooltip:before {
  border-bottom-color: var(--color-gray-700) !important;
}

.apexcharts-canvas .apexcharts-tooltip-series-group.apexcharts-active .apexcharts-tooltip-y-group {
  padding: 0 !important;
}

.apexcharts-canvas .apexcharts-tooltip-series-group.apexcharts-active {
  color: var(--color-gray-500) !important;
  background-color: #fff !important;
  padding-bottom: .75rem !important;
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.dark .apexcharts-canvas .apexcharts-tooltip-series-group.apexcharts-active {
  background-color: var(--color-gray-700) !important;
  color: var(--color-gray-400) !important;
}

.apexcharts-canvas .apexcharts-tooltip-series-group.apexcharts-active:first-of-type {
  padding-top: .75rem !important;
}

.apexcharts-canvas .apexcharts-legend {
  padding: 0 !important;
}

.apexcharts-canvas .apexcharts-legend-text {
  color: var(--color-gray-500) !important;
  padding-left: 1.25rem !important;
  font-size: 0 !important;
  font-weight: 500 !important;
}

[dir="rtl"] .apexcharts-canvas .apexcharts-legend-text {
  padding-right: .5rem !important;
}

.apexcharts-canvas .apexcharts-legend-text:not(.apexcharts-inactive-legend):hover {
  color: var(--color-gray-900) !important;
}

.dark .apexcharts-canvas .apexcharts-legend-text {
  color: var(--color-gray-400) !important;
}

.dark .apexcharts-canvas .apexcharts-legend-text:not(.apexcharts-inactive-legend):hover {
  color: #fff !important;
}

.apexcharts-canvas .apexcharts-legend-series {
  align-items: center !important;
  margin-bottom: .25rem !important;
  margin-left: .5rem !important;
  margin-right: .5rem !important;
  display: flex !important;
}

.apexcharts-datalabels-group .apexcharts-text.apexcharts-datalabel-value {
  fill: var(--color-gray-900) !important;
  font-size: 1.75rem !important;
  font-weight: 700 !important;
}

.dark .apexcharts-canvas .apexcharts-datalabels-group .apexcharts-text.apexcharts-datalabel-value {
  fill: #fff !important;
}

.apexcharts-canvas .apexcharts-datalabels-group .apexcharts-text.apexcharts-datalabel-label {
  fill: var(--color-gray-500) !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.dark .apexcharts-canvas .apexcharts-datalabels-group .apexcharts-text.apexcharts-datalabel-label {
  fill: var(--color-gray-400) !important;
}

.apexcharts-canvas .apexcharts-datalabels .apexcharts-text.apexcharts-pie-label {
  text-shadow: none !important;
  filter: none !important;
  font-size: .75rem !important;
  font-weight: 600 !important;
}

.apexcharts-gridline, .apexcharts-xcrosshairs, .apexcharts-ycrosshairs {
  stroke: var(--color-gray-200) !important;
}

.dark .apexcharts-gridline, .dark .apexcharts-xcrosshairs, .dark .apexcharts-ycrosshairs {
  stroke: var(--color-gray-700) !important;
}

.box {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  padding: 2rem;
}

.button {
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  border-radius: 1rem;
  justify-content: center;
  padding: .5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  display: inline-block;
}

@media (width >= 1024px) {
  .button {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.button.is-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(0 208 166 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-color: #0000;
}

.button.is-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  background-color: #00a685;
}

.navbar {
  z-index: 50;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 100%;
  padding: 1rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media (width >= 1280px) {
  .navbar {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.navbar .navbar-item {
  color: inherit;
  padding-left: .5rem;
  padding-right: .5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
}

.navbar .navbar-item:hover {
  font-weight: 700;
}

.navbar .navbar-item.is-active {
  color: #00d0a6 !important;
}

.navbar .navbar-item > svg {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (width >= 1024px) {
  .navbar .navbar-item > svg {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.navbar .button.border-primary {
  --tw-text-opacity: 1;
  color: rgb(0 208 166 / var(--tw-text-opacity));
}

.navbar.fixed:not(.is-scrolled) {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
}

.navbar.fixed:not(.is-scrolled):not(.is-dark) .navbar-item {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.navbar.fixed:not(.is-scrolled):not(.is-dark) #globe-icon, .navbar.fixed:not(.is-scrolled):not(.is-dark) #logo-icon-name, .navbar.fixed:not(.is-scrolled):not(.is-dark) button[data-drawer-target] > svg {
  fill: #fff;
}

.navbar.fixed:not(.is-scrolled):not(.is-dark) .button.border-primary {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.navbar #globe-icon, .navbar #logo-icon-name {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.content ol {
  margin-left: 1rem;
  list-style-type: decimal;
}

.content p, .content li p {
  margin-bottom: 1rem;
}

.content ul li {
  position: relative;
}

.content ul li:before {
  content: "•";
  color: #00d0a6;
  position: absolute;
  left: -1rem;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}

@media (width >= 768px) {
  .title {
    font-size: 1.75rem;
    line-height: 2.25rem;
  }
}

.hs-form-iframe {
  min-height: 1550px !important;
}

@media (width >= 375px) {
  .hs-form-iframe {
    min-height: 1500px !important;
  }
}

@media (width >= 400px) {
  .hs-form-iframe {
    min-height: 1400px !important;
  }
}

@media (width >= 480px) {
  .hs-form-iframe {
    min-height: 1060px !important;
  }
}

@media (width >= 768px) {
  .hs-form-iframe {
    min-height: 950px !important;
  }
}

#product-slider .active {
  --tw-border-opacity: 1;
  border-color: rgb(118 103 247 / var(--tw-border-opacity));
}

select:not([size]), .select-btn {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 19 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L9.75758 9L18 1' stroke='%238C8C8C'/%3E%3C/svg%3E");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  min-height: 50px;
  padding-right: 2.5rem !important;
}

.toggle-btn {
  transition: color .2s;
}

.highlight {
  transition: transform .2s, width .2s;
}

.pricing-cta-bg {
  background: linear-gradient(#fff3 0%, #2b77a233 28%, #4dc5c433 69%, #fff3 100%);
}

.\!monthly.crossed, .monthly.crossed {
  opacity: .5;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: relative;
}

.\!monthly.crossed:before, .monthly.crossed:before {
  border-width: 2px;
  position: absolute;
  left: 0;
  right: 0;
}

.\!monthly.crossed:before {
  content: "" !important;
  border-color: #273456 !important;
  top: 50% !important;
  transform: rotate(-15deg) !important;
}

.monthly.crossed:before {
  content: "";
  border-color: #273456;
  top: 50%;
  transform: rotate(-15deg);
}

.miller-box {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (width >= 1536px) {
  .miller-box {
    background-position: top;
    background-size: cover;
  }
}

@media (width <= 1023px) {
  .miller-box {
    --tw-bg-opacity: 1;
    background-color: rgb(39 52 86 / var(--tw-bg-opacity));
  }
}

@media (width >= 1024px) {
  .miller-box {
    background-image: url("../img/miller-bg.f21709c8.svg");
  }
}

.iti {
  display: block !important;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-top-6 {
  top: -1.5rem;
}

.-top-\[36px\] {
  top: -36px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bottom-\[60px\] {
  bottom: 60px;
}

.left-0 {
  left: 0;
}

.left-1 {
  left: .25rem;
}

.left-1\/2 {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-2 {
  right: .5rem;
}

.right-2\.5 {
  right: .625rem;
}

.right-6 {
  right: 1.5rem;
}

.right-7 {
  right: 1.75rem;
}

.right-\[-70\%\] {
  right: -70%;
}

.start-0 {
  inset-inline-start: 0;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-1\/2 {
  top: 50%;
}

.top-12 {
  top: 3rem;
}

.top-2 {
  top: .5rem;
}

.top-2\.5 {
  top: .625rem;
}

.top-4 {
  top: 1rem;
}

.-z-10 {
  z-index: -10;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-mb-24 {
  margin-bottom: -6rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-2 {
  height: .5rem;
}

.h-24 {
  height: 6rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-\[600px\] {
  height: 600px;
}

.h-\[610px\] {
  height: 610px;
}

.h-\[calc\(100\%-8px\)\] {
  height: calc(100% - 8px);
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.max-h-\[200px\] {
  max-height: 200px;
}

.min-h-\[200px\] {
  min-height: 200px;
}

.w-1\/2 {
  width: 50%;
}

.w-2 {
  width: .5rem;
}

.w-24 {
  width: 6rem;
}

.w-4 {
  width: 1rem;
}

.w-44 {
  width: 11rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-80 {
  width: 20rem;
}

.w-\[1rem\] {
  width: 1rem;
}

.w-\[230px\] {
  width: 230px;
}

.w-\[26px\] {
  width: 26px;
}

.w-full {
  width: 100%;
}

.min-w-\[10rem\] {
  min-width: 10rem;
}

.min-w-\[12rem\] {
  min-width: 12rem;
}

.min-w-\[14rem\] {
  min-width: 14rem;
}

.min-w-\[200px\] {
  min-width: 200px;
}

.min-w-\[8rem\] {
  min-width: 8rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-\[100px\] {
  max-width: 100px;
}

.max-w-\[1024px\] {
  max-width: 1024px;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[240px\] {
  max-width: 240px;
}

.max-w-\[400px\] {
  max-width: 400px;
}

.max-w-\[43rem\] {
  max-width: 43rem;
}

.max-w-\[44rem\] {
  max-width: 44rem;
}

.max-w-\[45rem\] {
  max-width: 45rem;
}

.max-w-\[550px\] {
  max-width: 550px;
}

.max-w-\[620px\] {
  max-width: 620px;
}

.max-w-\[640px\] {
  max-width: 640px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-1 {
  flex: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.grow {
  flex-grow: 1;
}

.basis-\[150\%\] {
  flex-basis: 150%;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-2 {
  --tw-translate-x: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-none {
  transform: none;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  animation: 30s linear infinite marquee;
}

@keyframes marquee2 {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.animate-marquee2 {
  animation: 30s linear infinite marquee2;
}

@keyframes scalePulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.animate-scalePulse {
  animation: 3s ease-in-out infinite scalePulse;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.snap-y {
  scroll-snap-type: y var(--tw-scroll-snap-strictness);
}

.snap-mandatory {
  --tw-scroll-snap-strictness: mandatory;
}

.snap-center {
  scroll-snap-align: center;
}

.snap-always {
  scroll-snap-stop: always;
}

.list-none {
  list-style-type: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-24 {
  gap: 6rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[4\.5rem\] {
  gap: 4.5rem;
}

.gap-x-20 {
  column-gap: 5rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(240 242 248 / var(--tw-divide-opacity));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-b-xl {
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-x {
  border-left-width: 1px;
  border-right-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-t-0 {
  border-top-width: 0;
}

.border-\[\#00ACAA\] {
  --tw-border-opacity: 1;
  border-color: rgb(0 172 170 / var(--tw-border-opacity));
}

.border-\[\#2C3256\] {
  --tw-border-opacity: 1;
  border-color: rgb(44 50 86 / var(--tw-border-opacity));
}

.border-\[\#7667F7\] {
  --tw-border-opacity: 1;
  border-color: rgb(118 103 247 / var(--tw-border-opacity));
}

.border-\[\#FDCD78\] {
  --tw-border-opacity: 1;
  border-color: rgb(253 205 120 / var(--tw-border-opacity));
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity));
}

.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(240 242 248 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(0 208 166 / var(--tw-border-opacity));
}

.border-teal {
  --tw-border-opacity: 1;
  border-color: rgb(0 172 170 / var(--tw-border-opacity));
}

.bg-\[\#1F2D4F\] {
  --tw-bg-opacity: 1;
  background-color: rgb(31 45 79 / var(--tw-bg-opacity));
}

.bg-\[\#273456\] {
  --tw-bg-opacity: 1;
  background-color: rgb(39 52 86 / var(--tw-bg-opacity));
}

.bg-\[\#2A2F45\] {
  --tw-bg-opacity: 1;
  background-color: rgb(42 47 69 / var(--tw-bg-opacity));
}

.bg-\[\#7667F7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(118 103 247 / var(--tw-bg-opacity));
}

.bg-\[\#F8FCFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 252 255 / var(--tw-bg-opacity));
}

.bg-\[\#FAA739\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 167 57 / var(--tw-bg-opacity));
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 242 248 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
}

.bg-gray-900\/50 {
  background-color: #d9dce580;
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/50 {
  background-color: #ffffff80;
}

.bg-\[url\(\'\.\/img\/about\/hero-bg\.svg\'\)\] {
  background-image: url("../img/hero-bg.457b9006.svg");
}

.bg-\[url\(\'\.\/img\/product\/finca-stage-bg\.svg\'\)\] {
  background-image: url("../img/finca-stage-bg.498401d9.svg");
}

.bg-cover {
  background-size: cover;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.object-cover {
  object-fit: cover;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-\[7rem\] {
  padding-bottom: 7rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-\[6\.5rem\] {
  padding-right: 6.5rem;
}

.ps-3 {
  padding-inline-start: .75rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pt-56 {
  padding-top: 14rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-\[10rem\] {
  padding-top: 10rem;
}

.pt-\[14rem\] {
  padding-top: 14rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.75rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.text-\[\#00BEB3\] {
  --tw-text-opacity: 1;
  color: rgb(0 190 179 / var(--tw-text-opacity));
}

.text-\[\#F6A300\] {
  --tw-text-opacity: 1;
  color: rgb(246 163 0 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-blueGray {
  --tw-text-opacity: 1;
  color: rgb(150 167 208 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(228 231 239 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(217 217 217 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(217 220 229 / var(--tw-text-opacity));
}

.text-inherit {
  color: inherit;
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(0 208 166 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity));
}

.text-violet-lighter {
  --tw-text-opacity: 1;
  color: rgb(118 103 247 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.short-dark-blue-background {
  background-image: linear-gradient(#1f2c50 600px, #fff 600px);
}

.select:not(.is-multiple):not(.is-loading):after {
  border-color: #273456;
}

.animate-pause, .animate-pause > * {
  animation-play-state: paused;
}

.blurred-before-after:before, .blurred-before-after:after {
  content: "";
  z-index: 1;
  background-image: linear-gradient(to right, #fff, #0000);
  width: 5rem;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width >= 768px) {
  .blurred-before-after:before, .blurred-before-after:after {
    width: 10rem;
  }
}

.blurred-before-after:after {
  background-image: linear-gradient(to left, #fff, #0000);
  left: auto;
  right: 0;
}

@media (width >= 1280px) {
  .xl\:container {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (width >= 640px) {
    .xl\:container {
      max-width: 640px;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  @media (width >= 768px) {
    .xl\:container {
      max-width: 768px;
    }
  }

  @media (width >= 1024px) {
    .xl\:container {
      max-width: 1024px;
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }

  @media (width >= 1280px) {
    .xl\:container {
      max-width: 1280px;
      padding-left: 6rem;
      padding-right: 6rem;
    }
  }

  @media (width >= 1536px) {
    .xl\:container {
      max-width: 1536px;
      padding-left: 6rem;
      padding-right: 6rem;
    }
  }
}

.placeholder\:text-\[\#8C8C8C\]::placeholder {
  --tw-text-opacity: 1;
  color: rgb(140 140 140 / var(--tw-text-opacity));
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-\[1\.02\]:hover {
  --tw-scale-x: 1.02;
  --tw-scale-y: 1.02;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.hover\:border-teal:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 172 170 / var(--tw-border-opacity));
}

.hover\:border-violet-lighter:hover {
  --tw-border-opacity: 1;
  border-color: rgb(118 103 247 / var(--tw-border-opacity));
}

.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 242 248 / var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(217 220 229 / var(--tw-text-opacity));
}

.hover\:text-inherit:hover {
  color: inherit;
}

.hover\:text-violet-lighter:hover {
  --tw-text-opacity: 1;
  color: rgb(118 103 247 / var(--tw-text-opacity));
}

.hover\:shadow-\[0_0_30px_rgba\(45\,212\,191\,0\.2\)\]:hover {
  --tw-shadow: 0 0 30px #2dd4bf33;
  --tw-shadow-colored: 0 0 30px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity));
}

.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
}

.group[open] .group-open\:rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group.active .group-\[\.active\]\:bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(0 208 166 / var(--tw-bg-opacity));
}

.group.active .group-\[\.active\]\:font-medium {
  font-weight: 500;
}

.group.active .group-\[\.active\]\:text-violet-lighter {
  --tw-text-opacity: 1;
  color: rgb(118 103 247 / var(--tw-text-opacity));
}

:is([dir="rtl"] .rtl\:rotate-180) {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

:is([dir="rtl"] .rtl\:space-x-reverse) > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}

@media (prefers-color-scheme: dark) {
  .dark\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-border-opacity));
  }

  .dark\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity));
  }

  .dark\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }

  .dark\:border-transparent {
    border-color: #0000;
  }

  .dark\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity));
  }

  .dark\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  }

  .dark\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  }

  .dark\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(217 217 217 / var(--tw-bg-opacity));
  }

  .dark\:bg-gray-800\/50 {
    background-color: #d9d9d980;
  }

  .dark\:bg-gray-900\/80 {
    background-color: #d9dce5cc;
  }

  .dark\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
  }

  .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }

  .dark\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(228 231 239 / var(--tw-text-opacity));
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .dark\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(29 78 216 / var(--tw-bg-opacity));
  }

  .dark\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  }

  .dark\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(217 217 217 / var(--tw-bg-opacity));
  }

  .dark\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
  }

  .dark\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
  }

  .dark\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

@media (width >= 640px) {
  .sm\:w-3\/5 {
    width: 60%;
  }

  .sm\:gap-10 {
    gap: 2.5rem;
  }
}

@media (width >= 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-2\/5 {
    width: 40%;
  }

  .md\:w-\[190px\] {
    width: 190px;
  }

  .md\:max-w-\[30rem\] {
    max-width: 30rem;
  }

  .md\:flex-1 {
    flex: 1;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:gap-2 {
    gap: .5rem;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:gap-\[4\.5rem\] {
    gap: 4.5rem;
  }

  .md\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:bg-\[url\(\'\.\/img\/about\/hero-tablet\.svg\'\)\,_url\(\'\.\/img\/about\/hero-bg\.svg\'\)\] {
    background-image: url("../img/hero-tablet.1892b8bb.svg"), url("../img/hero-bg.457b9006.svg");
  }

  .md\:bg-\[contain\,_cover\] {
    background-size: contain, cover;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .md\:pb-24 {
    padding-bottom: 6rem;
  }

  .md\:pt-56 {
    padding-top: 14rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-\[33px\] {
    font-size: 33px;
  }

  .md\:leading-snug {
    line-height: 1.375;
  }

  .md\:leading-tight {
    line-height: 1.25;
  }

  .md\:bg-top-padding {
    background-position: 50% 78px, bottom;
  }
}

@media (width >= 1024px) {
  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:ml-1 {
    margin-left: .25rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-48 {
    height: 12rem;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-48 {
    width: 12rem;
  }

  .lg\:w-\[230px\] {
    width: 230px;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:min-w-\[15rem\] {
    min-width: 15rem;
  }

  .lg\:max-w-2xl {
    max-width: 42rem;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:gap-20 {
    gap: 5rem;
  }

  .lg\:gap-8 {
    gap: 2rem;
  }

  .lg\:gap-\[4\.5rem\] {
    gap: 4.5rem;
  }

  .lg\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .lg\:border-l-4 {
    border-left-width: 4px;
  }

  .lg\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgb(217 217 217 / var(--tw-border-opacity));
  }

  .lg\:bg-\[url\(\'\.\/img\/about\/hero\.svg\'\)\,_url\(\'\.\/img\/about\/hero-bg\.svg\'\)\] {
    background-image: url("../img/hero.ab24c2c9.svg"), url("../img/hero-bg.457b9006.svg");
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:p-16 {
    padding: 4rem;
  }

  .lg\:p-2 {
    padding: .5rem;
  }

  .lg\:p-20 {
    padding: 5rem;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .lg\:pb-40 {
    padding-bottom: 10rem;
  }

  .lg\:pt-16 {
    padding-top: 4rem;
  }

  .lg\:pt-28 {
    padding-top: 7rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-3xl {
    font-size: 1.75rem;
    line-height: 2.25rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:leading-relaxed {
    line-height: 1.625;
  }
}

@media (width >= 1280px) {
  .xl\:pointer-events-none {
    pointer-events: none;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:w-full {
    width: 100%;
  }

  .xl\:max-w-\[120px\] {
    max-width: 120px;
  }

  .xl\:max-w-\[60px\] {
    max-width: 60px;
  }

  .xl\:animate-none {
    animation: none;
  }

  .xl\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:bg-contain {
    background-size: contain;
  }

  .xl\:p-6 {
    padding: 1.5rem;
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .xl\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xl\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .xl\:pl-40 {
    padding-left: 10rem;
  }

  .xl\:no-before-after:before, .xl\:no-before-after:after {
    display: none;
  }
}

@media (width >= 1536px) {
  .\32 xl\:bg-\[length\:100\%_100\%\] {
    background-size: 100% 100%;
  }
}
/*# sourceMappingURL=about.3f56a70b.css.map */
